import { IIconComponent } from "@/services/types/types";
import { FC } from "react";

export const RuStoreNormalIcon: FC<IIconComponent> = ({ className }) => {
  return (
    <svg width="180" height="52" viewBox="0 0 180 52" className={className} fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_19444_73926)">
        <path d="M173.333 0H6.66667C2.98477 0 0 2.85418 0 6.375V44.625C0 48.1458 2.98477 51 6.66667 51H173.333C177.015 51 180 48.1458 180 44.625V6.375C180 2.85418 177.015 0 173.333 0Z" fill="black" />
        <path d="M173.333 0.831993C174.819 0.831993 176.243 1.40724 177.293 2.43119C178.343 3.45514 178.933 4.84391 178.933 6.29199V45.292C178.933 46.7401 178.343 48.1288 177.293 49.1528C176.243 50.1767 174.819 50.752 173.333 50.752H6.66667C5.18145 50.752 3.75707 50.1767 2.70687 49.1528C1.65666 48.1288 1.06667 46.7401 1.06667 45.292V6.29199C1.06667 4.84391 1.65666 3.45514 2.70687 2.43119C3.75707 1.40724 5.18145 0.831993 6.66667 0.831993H173.333ZM173.333 -0.208008H6.66667C4.89856 -0.208008 3.20286 0.476812 1.95262 1.6958C0.702379 2.91478 0 4.56809 0 6.29199V45.292C0 47.0159 0.702379 48.6692 1.95262 49.8882C3.20286 51.1072 4.89856 51.792 6.66667 51.792H173.333C175.101 51.792 176.797 51.1072 178.047 49.8882C179.298 48.6692 180 47.0159 180 45.292V6.29199C180 4.56809 179.298 2.91478 178.047 1.6958C176.797 0.476812 175.101 -0.208008 173.333 -0.208008Z" fill="#A6A6A6" />
        <g clipPath="url(#clip1_19444_73926)">
          <path fillRule="evenodd" clipRule="evenodd" d="M36.222 43.88C28.1035 43.88 24.0442 43.88 21.5221 41.3578C19 38.8357 19 34.7763 19 26.6575L19 25.2223C19 17.1036 19 13.0442 21.5221 10.522C24.0442 8 28.1035 8 36.2219 8L37.6571 8C45.7757 8 49.8349 8 52.357 10.522C54.8791 13.0442 54.8791 17.1036 54.8791 25.2223V26.6575C54.8791 34.7763 54.8791 38.8357 52.357 41.3578C49.8349 43.88 45.7757 43.88 37.6571 43.88H36.222Z" fill="#0077FF" />
          <path d="M39.7461 30.1114C38.7794 29.8699 38.1016 29.0052 38.1016 28.0134V16.3288C38.1016 15.2022 39.166 14.3761 40.264 14.6503L47.2172 16.3873C48.1839 16.6288 48.8618 17.4936 48.8618 18.4853V30.17C48.8618 31.2965 47.7973 32.1227 46.6993 31.8484L39.7461 30.1114Z" fill="white" />
          <path d="M26.6601 35.4918C25.6934 35.2503 25.0156 34.3855 25.0156 33.3937V21.7091C25.0156 20.5826 26.0801 19.7564 27.178 20.0307L34.1313 21.7677C35.0979 22.0092 35.7758 22.8739 35.7758 23.8657V35.5503C35.7758 36.6768 34.7113 37.5031 33.6133 37.2287L26.6601 35.4918Z" fill="white" />
          <path d="M33.2031 32.8018C32.2364 32.5603 31.5586 31.6956 31.5586 30.7038V19.0192C31.5586 17.8927 32.6231 17.0665 33.721 17.3408L40.6743 19.0778C41.641 19.3193 42.3187 20.184 42.3187 21.1758V32.8604C42.3187 33.9869 41.2543 34.8131 40.1563 34.5388L33.2031 32.8018Z" fill="white" />
          <path d="M37.7555 33.9416C37.451 33.8641 37.2343 33.5948 37.2236 33.2808L36.9212 24.3573C36.8337 23.1763 35.957 22.237 35.0409 21.9604C34.9894 21.9449 34.9345 21.9658 34.9041 22.01C34.8731 22.055 34.8851 22.1173 34.9282 22.1507C35.1545 22.3258 35.7771 22.8973 35.7771 23.8831L35.7753 33.4398L37.7555 33.9416Z" fill="#0077FF" />
          <path d="M44.2985 31.249C43.9943 31.1729 43.7773 30.9044 43.7667 30.5909L43.4641 21.6681C43.3766 20.4871 42.5 19.5478 41.5838 19.2712C41.5324 19.2557 41.4775 19.2766 41.447 19.3208C41.4161 19.3658 41.428 19.4281 41.4712 19.4615C41.6974 19.6366 42.3201 20.2081 42.3201 21.1939L42.3183 30.7539L44.2985 31.249Z" fill="#0077FF" />
          <path d="M75.5344 35.0164C78.2514 34.3237 80.0368 32.2455 80.0368 29.526C80.0368 26.165 77.3455 23.8047 73.5676 23.8047H66.8398V41.8922H69.945V35.2473H72.1447L76.9573 41.8922H80.606L75.5344 35.0164ZM73.5676 26.6269C75.5083 26.6269 76.8542 27.807 76.8542 29.526C76.8542 31.2449 75.5083 32.4251 73.5676 32.4251H69.945V26.6269H73.5676Z" fill="white" />
          <path d="M87.5928 42.1488C89.2228 42.1488 90.7236 41.3021 91.5778 39.891L92.1727 41.8922H94.3984V28.551H91.4225V36.2735C91.4225 38.1721 90.1545 39.5062 88.4209 39.5062C86.7129 39.5062 85.5224 38.1721 85.5224 36.2735V28.551H82.5469V36.6071C82.5469 39.8141 84.7204 42.1488 87.5928 42.1488Z" fill="white" />
          <path d="M103.898 42.1488C107.339 42.1488 110.186 40.1989 110.186 36.9662C110.186 33.3487 107.21 32.2968 104.648 31.4245C102.656 30.7575 100.922 30.1417 100.922 28.5254C100.922 27.1143 102.242 26.3703 103.794 26.3703C105.425 26.3703 106.589 27.2169 107.236 28.6537L110.186 28.1662C109.254 25.344 106.899 23.5481 103.691 23.5481C100.379 23.5481 97.7392 25.4466 97.7392 28.6793C97.7392 32.3225 100.715 33.3231 103.277 34.1697C105.244 34.8112 107.003 35.4269 107.003 37.0689C107.003 38.5056 105.684 39.3266 103.924 39.3266C101.905 39.3266 100.819 38.3773 99.8614 36.6584L96.8594 37.1715C97.7392 40.2246 100.534 42.1488 103.898 42.1488Z" fill="white" />
          <path d="M118.281 39.3266C117.272 39.3266 116.781 38.8392 116.781 37.8386V31.1167H120.791V28.5511H116.781V24.7026H114.555L113.805 28.5511H111.605V31.1167H113.805V37.8386C113.805 40.4555 115.332 41.8923 118.281 41.8923H120.791L121.05 39.3266H118.281Z" fill="white" />
          <path d="M129.553 42.1487C133.771 42.1487 136.746 39.2753 136.746 35.2216C136.746 31.1679 133.771 28.2944 129.553 28.2944C125.335 28.2944 122.359 31.1679 122.359 35.2216C122.359 39.2753 125.335 42.1487 129.553 42.1487ZM129.553 39.4548C127.121 39.4548 125.465 37.7359 125.465 35.2216C125.465 32.7073 127.121 30.9883 129.553 30.9883C131.986 30.9883 133.641 32.7073 133.641 35.2216C133.641 37.7359 131.986 39.4548 129.553 39.4548Z" fill="white" />
          <path d="M142.308 41.8922V34.0158C142.308 32.3225 143.136 31.3732 144.947 31.3732H146.655L146.396 28.551H145.827C144.248 28.551 143.032 28.9872 142.127 30.4753L141.557 28.551H139.332V41.8922H142.308Z" fill="white" />
          <path d="M161.298 35.0676C161.298 31.2192 158.399 28.2944 154.311 28.2944C150.223 28.2944 147.324 31.1936 147.324 35.2216C147.324 39.2496 150.223 42.1487 154.311 42.1487C157.106 42.1487 159.745 40.8403 160.754 38.3003L157.96 37.8385C157.313 38.7878 156.071 39.4548 154.492 39.4548C152.215 39.4548 150.714 38.0694 150.429 36.0169H161.246C161.272 35.7347 161.298 35.4268 161.298 35.0676ZM154.311 30.8601C156.174 30.8601 157.571 32.0402 158.063 33.7079H150.611C151.103 31.9889 152.5 30.8601 154.311 30.8601Z" fill="white" />
          <path d="M67.0898 19.2711V16.659H67.2606C67.4819 16.659 67.659 16.6048 67.7918 16.4963C67.9246 16.3879 68.0353 16.171 68.1238 15.8457C68.2187 15.514 68.2914 15.0261 68.342 14.3818L68.6171 10.8225H72.829V16.659H73.8061V19.2711H72.829V17.5679H68.0669V19.2711H67.0898ZM71.8519 16.659V11.7315H69.5183L69.3191 14.4296C69.2432 15.5076 69.0472 16.2508 68.731 16.659H71.8519Z" fill="white" />
          <path d="M76.9121 17.6636C76.4125 17.6636 75.9666 17.5552 75.5745 17.3383C75.1824 17.1214 74.8757 16.8185 74.6544 16.4294C74.433 16.0403 74.3223 15.5969 74.3223 15.0994C74.3223 14.6019 74.433 14.1586 74.6544 13.7695C74.8757 13.3804 75.1824 13.0774 75.5745 12.8605C75.9666 12.6372 76.4125 12.5256 76.9121 12.5256C77.4117 12.5256 77.8575 12.6372 78.2496 12.8605C78.6417 13.0774 78.9485 13.3804 79.1698 13.7695C79.3912 14.1586 79.5018 14.6019 79.5018 15.0994C79.5018 15.5969 79.3912 16.0403 79.1698 16.4294C78.9485 16.8185 78.6417 17.1214 78.2496 17.3383C77.8575 17.5552 77.4117 17.6636 76.9121 17.6636ZM76.9121 16.8025C77.3927 16.8025 77.7817 16.6462 78.0789 16.3337C78.3825 16.0211 78.5342 15.6097 78.5342 15.0994C78.5342 14.5891 78.3825 14.1777 78.0789 13.8651C77.7817 13.5462 77.3927 13.3867 76.9121 13.3867C76.4315 13.3867 76.0425 13.5462 75.7453 13.8651C75.448 14.1777 75.2994 14.5891 75.2994 15.0994C75.2994 15.6033 75.448 16.0147 75.7453 16.3337C76.0425 16.6462 76.4315 16.8025 76.9121 16.8025Z" fill="white" />
          <path d="M82.7565 17.6636C82.2695 17.6636 81.83 17.5552 81.4379 17.3383C81.0521 17.1151 80.7517 16.8089 80.5367 16.4198C80.3217 16.0307 80.2142 15.5906 80.2142 15.0994C80.2142 14.6019 80.3217 14.1586 80.5367 13.7695C80.7517 13.3804 81.0521 13.0774 81.4379 12.8605C81.8237 12.6372 82.2632 12.5256 82.7565 12.5256C83.2814 12.5256 83.7494 12.6532 84.1604 12.9083C84.5778 13.1635 84.8751 13.5303 85.0522 14.0087L84.1794 14.1522C84.0656 13.9225 83.8854 13.7376 83.6387 13.5972C83.3984 13.4569 83.1233 13.3867 82.8134 13.3867C82.3328 13.3867 81.9407 13.5462 81.6371 13.8651C81.3399 14.1841 81.1913 14.5955 81.1913 15.0994C81.1913 15.6033 81.3399 16.0147 81.6371 16.3337C81.9407 16.6462 82.3328 16.8025 82.8134 16.8025C83.1359 16.8025 83.4205 16.7292 83.6672 16.5824C83.9201 16.4357 84.1035 16.2412 84.2174 15.9988L85.0901 16.1423C84.913 16.6271 84.6095 17.0034 84.1794 17.2713C83.7557 17.5329 83.2814 17.6636 82.7565 17.6636Z" fill="white" />
          <path d="M87.9604 17.5679H87.0307V13.4824H85.3327V12.6213H89.6584V13.4824H87.9604V17.5679Z" fill="white" />
          <path d="M94.0503 12.6213H95.0084L92.9594 18.3143C92.8076 18.7416 92.6084 19.051 92.3617 19.2423C92.1151 19.4337 91.7862 19.5294 91.3752 19.5294H90.6447L90.5594 18.6683H91.4321C91.6155 18.6683 91.7578 18.6268 91.859 18.5439C91.9602 18.4673 92.0455 18.3302 92.1151 18.1325L92.2194 17.8454L90.0376 12.6213H91.0337L92.5894 16.4389H92.7222L94.0503 12.6213Z" fill="white" />
          <path d="M96.7787 17.5679H95.849V12.6213H100.023V17.5679H99.0933V13.4824H96.7787V17.5679Z" fill="white" />
          <path d="M104.597 14.6401V12.6213H105.527V17.5679H104.597V15.5013H102.235V17.5679H101.305V12.6213H102.235V14.6401H104.597Z" fill="white" />
          <path d="M109.113 17.6636C108.614 17.6636 108.168 17.5552 107.776 17.3383C107.384 17.1214 107.077 16.8185 106.856 16.4294C106.634 16.0403 106.524 15.5969 106.524 15.0994C106.524 14.6019 106.634 14.1586 106.856 13.7695C107.077 13.3804 107.384 13.0774 107.776 12.8605C108.168 12.6372 108.614 12.5256 109.113 12.5256C109.613 12.5256 110.059 12.6372 110.451 12.8605C110.843 13.0774 111.15 13.3804 111.371 13.7695C111.592 14.1586 111.703 14.6019 111.703 15.0994C111.703 15.5969 111.592 16.0403 111.371 16.4294C111.15 16.8185 110.843 17.1214 110.451 17.3383C110.059 17.5552 109.613 17.6636 109.113 17.6636ZM109.113 16.8025C109.594 16.8025 109.983 16.6462 110.28 16.3337C110.584 16.0211 110.736 15.6097 110.736 15.0994C110.736 14.5891 110.584 14.1777 110.28 13.8651C109.983 13.5462 109.594 13.3867 109.113 13.3867C108.633 13.3867 108.244 13.5462 107.947 13.8651C107.649 14.1777 107.501 14.5891 107.501 15.0994C107.501 15.6033 107.649 16.0147 107.947 16.3337C108.244 16.6462 108.633 16.8025 109.113 16.8025Z" fill="white" />
          <path d="M118.233 14.975C118.486 15.0707 118.688 15.227 118.84 15.4439C118.998 15.6543 119.077 15.9031 119.077 16.1902C119.077 16.4517 119.001 16.6877 118.849 16.8982C118.704 17.1087 118.498 17.2745 118.233 17.3957C117.967 17.5105 117.664 17.5679 117.322 17.5679H114.979V12.6213H117.237C117.559 12.6213 117.847 12.6787 118.1 12.7935C118.353 12.9083 118.549 13.0646 118.688 13.2623C118.834 13.4601 118.906 13.6865 118.906 13.9417C118.906 14.1841 118.846 14.3946 118.726 14.5732C118.612 14.7454 118.448 14.8793 118.233 14.975ZM115.909 13.4154V14.6306H117.237C117.452 14.6306 117.629 14.5764 117.768 14.4679C117.907 14.3531 117.977 14.2064 117.977 14.0278C117.977 13.8492 117.907 13.7025 117.768 13.5877C117.629 13.4728 117.452 13.4154 117.237 13.4154H115.909ZM117.322 16.7738C117.569 16.7738 117.768 16.71 117.92 16.5824C118.071 16.4549 118.147 16.289 118.147 16.0849C118.147 15.8808 118.071 15.7149 117.92 15.5874C117.768 15.4598 117.569 15.396 117.322 15.396H115.909V16.7738H117.322Z" fill="white" />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_19444_73926">
          <rect width="180" height="52" fill="white" />
        </clipPath>
        <clipPath id="clip1_19444_73926">
          <rect width="142.293" height="35.88" fill="white" transform="translate(19 8)" />
        </clipPath>
      </defs>
    </svg>
  );
};
