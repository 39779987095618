import { IIconComponent } from "@/services/types/types";
import { FC } from "react";

export const AppStoreNormalIcon: FC<IIconComponent> = ({ className }) => {
  return (
    <svg width="180" height="52" viewBox="0 0 180 52" fill="none" className={className} xmlns="http://www.w3.org/2000/svg">
      <path d="M179 46.0031C179 48.7743 176.709 51.0189 173.874 51.0189H6.13243C3.2995 51.0189 1 48.7743 1 46.0031V6.00357C1 3.23374 3.2995 0.981201 6.13243 0.981201H173.873C176.709 0.981201 178.999 3.23374 178.999 6.00357L179 46.0031Z" fill="black" />
      <path d="M173.333 1.04162C176.42 1.04162 178.932 3.4905 178.932 6.5V45.5C178.932 48.5095 176.42 50.9584 173.333 50.9584H6.66667C3.58 50.9584 1.06833 48.5095 1.06833 45.5V6.5C1.06833 3.4905 3.58 1.04162 6.66667 1.04162H173.333ZM173.333 2.74817e-06H6.66667C3.00167 2.74817e-06 0 2.92663 0 6.5V45.5C0 49.0734 3.00167 52 6.66667 52H173.333C176.998 52 180 49.0734 180 45.5V6.5C180 2.92663 176.998 2.74817e-06 173.333 2.74817e-06Z" fill="#A6A6A6" />
      <path d="M40.1695 25.7191C40.1308 21.5292 43.6881 19.4908 43.8508 19.3959C41.8361 16.532 38.7135 16.1407 37.6161 16.1095C34.9935 15.8404 32.4495 17.6396 31.1135 17.6396C29.7508 17.6396 27.6935 16.1355 25.4761 16.1797C22.6228 16.2226 19.9535 17.8333 18.4895 20.3345C15.4681 25.4344 17.7215 32.9289 20.6161 37.0512C22.0641 39.0701 23.7561 41.3243 25.9708 41.245C28.1375 41.1579 28.9468 39.8982 31.5615 39.8982C34.1521 39.8982 34.9121 41.245 37.1708 41.1943C39.4961 41.1579 40.9601 39.1663 42.3575 37.1292C44.0308 34.8152 44.7028 32.5363 44.7295 32.4193C44.6748 32.4011 40.2135 30.741 40.1695 25.7191Z" fill="white" />
      <path d="M35.9028 13.3977C37.0681 11.9768 37.8655 10.0437 37.6441 8.08203C35.9575 8.15483 33.8481 9.21953 32.6335 10.6092C31.5588 11.8338 30.5988 13.841 30.8468 15.7286C32.7415 15.8664 34.6868 14.7965 35.9028 13.3977Z" fill="white" />
      <path d="M71.5269 40.9552H68.4989L66.8402 35.8735H61.0749L59.4949 40.9552H56.5469L62.2589 23.6548H65.7869L71.5269 40.9552ZM66.3402 33.7415L64.8402 29.224C64.6815 28.7625 64.3842 27.6757 63.9455 25.9649H63.8922C63.7175 26.7007 63.4362 27.7875 63.0495 29.224L61.5762 33.7415H66.3402Z" fill="white" />
      <path d="M86.2159 34.5645C86.2159 36.6861 85.6279 38.3631 84.4519 39.5942C83.3985 40.6901 82.0905 41.2374 80.5292 41.2374C78.8439 41.2374 77.6332 40.6472 76.8959 39.4668H76.8425V46.0383H73.9999V32.5872C73.9999 31.2534 73.9639 29.8845 73.8945 28.4805H76.3945L76.5532 30.4578H76.6065C77.5545 28.968 78.9932 28.2244 80.9239 28.2244C82.4332 28.2244 83.6932 28.8055 84.7012 29.969C85.7119 31.1338 86.2159 32.6652 86.2159 34.5645ZM83.3199 34.6659C83.3199 33.4517 83.0399 32.4507 82.4772 31.6629C81.8625 30.8413 81.0372 30.4305 80.0025 30.4305C79.3012 30.4305 78.6639 30.6593 78.0945 31.1104C77.5239 31.5654 77.1505 32.1595 76.9759 32.8953C76.8879 33.2385 76.8439 33.5193 76.8439 33.7403V35.8203C76.8439 36.7277 77.1292 37.4934 77.6999 38.1187C78.2705 38.744 79.0119 39.056 79.9239 39.056C80.9945 39.056 81.8279 38.653 82.4239 37.8496C83.0212 37.0449 83.3199 35.9841 83.3199 34.6659Z" fill="white" />
      <path d="M100.933 34.5645C100.933 36.6861 100.345 38.3631 99.168 39.5942C98.116 40.6901 96.8079 41.2374 95.2466 41.2374C93.5613 41.2374 92.3506 40.6472 91.6146 39.4668H91.5613V46.0383H88.7186V32.5872C88.7186 31.2534 88.6826 29.8845 88.6133 28.4805H91.1133L91.272 30.4578H91.3253C92.2719 28.968 93.7106 28.2244 95.6426 28.2244C97.1506 28.2244 98.4106 28.8055 99.4213 29.969C100.428 31.1338 100.933 32.6652 100.933 34.5645ZM98.0373 34.6659C98.0373 33.4517 97.7559 32.4507 97.1933 31.6629C96.5786 30.8413 95.756 30.4305 94.72 30.4305C94.0173 30.4305 93.3813 30.6593 92.8106 31.1104C92.24 31.5654 91.8679 32.1595 91.6933 32.8953C91.6066 33.2385 91.5613 33.5193 91.5613 33.7403V35.8203C91.5613 36.7277 91.8466 37.4934 92.4146 38.1187C92.9853 38.7427 93.7266 39.056 94.6413 39.056C95.712 39.056 96.5453 38.653 97.1413 37.8496C97.7386 37.0449 98.0373 35.9841 98.0373 34.6659Z" fill="white" />
      <path d="M117.387 36.1037C117.387 37.5753 116.863 38.7726 115.811 39.6969C114.655 40.707 113.046 41.2114 110.978 41.2114C109.068 41.2114 107.538 40.8526 106.379 40.1337L107.038 37.8236C108.286 38.5594 109.655 38.9286 111.147 38.9286C112.218 38.9286 113.051 38.692 113.65 38.2214C114.246 37.7508 114.543 37.119 114.543 36.3312C114.543 35.6292 114.298 35.0377 113.806 34.558C113.316 34.0783 112.499 33.6324 111.358 33.2203C108.251 32.0906 106.699 30.4357 106.699 28.2595C106.699 26.8373 107.243 25.6712 108.332 24.7638C109.418 23.8551 110.866 23.4014 112.676 23.4014C114.291 23.4014 115.632 23.6757 116.703 24.223L115.992 26.4824C114.992 25.952 113.862 25.6868 112.596 25.6868C111.596 25.6868 110.815 25.9273 110.255 26.4057C109.782 26.8334 109.544 27.3547 109.544 27.9722C109.544 28.656 109.815 29.2215 110.359 29.6661C110.832 30.0769 111.692 30.5215 112.94 31.0012C114.467 31.6005 115.588 32.3012 116.31 33.1046C117.028 33.9054 117.387 34.9077 117.387 36.1037Z" fill="white" />
      <path d="M126.784 30.5604H123.651V36.6171C123.651 38.1576 124.203 38.9272 125.31 38.9272C125.818 38.9272 126.239 38.8843 126.572 38.7985L126.651 40.9032C126.091 41.1073 125.354 41.21 124.44 41.21C123.318 41.21 122.44 40.8759 121.807 40.209C121.176 39.5408 120.859 38.4202 120.859 36.8459V30.5578H118.992V28.4778H120.859V26.1937L123.651 25.3721V28.4778H126.784V30.5604Z" fill="white" />
      <path d="M140.923 34.6151C140.923 36.5326 140.36 38.1069 139.237 39.338C138.06 40.6055 136.497 41.2373 134.549 41.2373C132.672 41.2373 131.177 40.6302 130.063 39.416C128.948 38.2018 128.391 36.6691 128.391 34.8218C128.391 32.8887 128.964 31.3053 130.115 30.0742C131.263 28.8418 132.812 28.2256 134.76 28.2256C136.637 28.2256 138.148 28.8327 139.288 30.0482C140.379 31.2273 140.923 32.7496 140.923 34.6151ZM137.973 34.7048C137.973 33.5543 137.721 32.5676 137.211 31.7447C136.615 30.7489 135.763 30.2523 134.659 30.2523C133.516 30.2523 132.648 30.7502 132.052 31.7447C131.541 32.5689 131.289 33.5712 131.289 34.7568C131.289 35.9073 131.541 36.894 132.052 37.7156C132.667 38.7114 133.525 39.208 134.633 39.208C135.719 39.208 136.571 38.701 137.185 37.6896C137.709 36.8511 137.973 35.854 137.973 34.7048Z" fill="white" />
      <path d="M150.162 30.918C149.88 30.8673 149.58 30.8413 149.266 30.8413C148.266 30.8413 147.492 31.2092 146.948 31.9463C146.475 32.5963 146.238 33.4179 146.238 34.4098V40.9553H143.396L143.423 32.4091C143.423 30.9713 143.387 29.6622 143.316 28.4818H145.792L145.896 30.8686H145.975C146.275 30.0483 146.748 29.3879 147.396 28.8926C148.03 28.4467 148.714 28.2244 149.451 28.2244C149.714 28.2244 149.951 28.2426 150.162 28.2751V30.918Z" fill="white" />
      <path d="M162.875 34.1276C162.875 34.6242 162.841 35.0428 162.771 35.3847H154.243C154.276 36.6171 154.688 37.5596 155.48 38.2096C156.199 38.7907 157.128 39.0819 158.269 39.0819C159.532 39.0819 160.684 38.8856 161.72 38.4917L162.165 40.4157C160.955 40.9305 159.525 41.1866 157.876 41.1866C155.892 41.1866 154.335 40.6172 153.201 39.4797C152.071 38.3422 151.504 36.8147 151.504 34.8985C151.504 33.0174 152.031 31.4509 153.085 30.2016C154.189 28.8678 155.681 28.2009 157.559 28.2009C159.403 28.2009 160.799 28.8678 161.747 30.2016C162.497 31.2611 162.875 32.5715 162.875 34.1276ZM160.164 33.4087C160.183 32.5871 159.997 31.8773 159.612 31.278C159.12 30.5071 158.364 30.1223 157.347 30.1223C156.417 30.1223 155.661 30.498 155.084 31.252C154.611 31.8513 154.329 32.5702 154.243 33.4074H160.164V33.4087Z" fill="white" />
      <path d="M65.3997 13.0116C65.3997 14.5417 64.9291 15.6935 63.989 16.467C63.1184 17.1807 61.881 17.5382 60.2784 17.5382C59.4837 17.5382 58.8037 17.5044 58.2344 17.4368V9.07646C58.977 8.95946 59.777 8.89966 60.641 8.89966C62.1677 8.89966 63.3184 9.22336 64.0944 9.87076C64.9637 10.6027 65.3997 11.6492 65.3997 13.0116ZM63.9264 13.0493C63.9264 12.0574 63.657 11.2969 63.1184 10.7665C62.5797 10.2374 61.793 9.97216 60.757 9.97216C60.317 9.97216 59.9424 10.0008 59.6317 10.0606V16.4163C59.8037 16.4423 60.1184 16.454 60.5757 16.454C61.645 16.454 62.4704 16.1641 63.0517 15.5843C63.6331 15.0045 63.9264 14.1595 63.9264 13.0493Z" fill="white" />
      <path d="M73.2107 14.3479C73.2107 15.2904 72.9347 16.0626 72.3827 16.6684C71.804 17.2911 71.0374 17.6018 70.08 17.6018C69.1574 17.6018 68.4227 17.3041 67.8747 16.7061C67.328 16.1094 67.0547 15.3567 67.0547 14.4493C67.0547 13.5003 67.336 12.7216 67.9014 12.1171C68.4667 11.5126 69.2267 11.2097 70.184 11.2097C71.1067 11.2097 71.848 11.5074 72.4094 12.1041C72.9427 12.6839 73.2107 13.4327 73.2107 14.3479ZM71.7614 14.3921C71.7614 13.8266 71.636 13.3417 71.3867 12.9374C71.0934 12.4486 70.676 12.2042 70.1334 12.2042C69.572 12.2042 69.1454 12.4486 68.852 12.9374C68.6014 13.3417 68.4774 13.8344 68.4774 14.4168C68.4774 14.9823 68.6027 15.4672 68.852 15.8715C69.1547 16.3603 69.576 16.6047 70.12 16.6047C70.6534 16.6047 71.072 16.3564 71.3734 15.8585C71.632 15.4464 71.7614 14.9576 71.7614 14.3921Z" fill="white" />
      <path d="M83.6848 11.3347L81.7181 17.4629H80.4381L79.6235 14.8018C79.4168 14.1375 79.2488 13.4771 79.1181 12.8219H79.0928C78.9715 13.4953 78.8035 14.1544 78.5875 14.8018L77.7221 17.4629H76.4275L74.5781 11.3347H76.0141L76.7248 14.248C76.8968 14.937 77.0381 15.5935 77.1515 16.2149H77.1768C77.2808 15.7027 77.4528 15.0501 77.6955 14.261L78.5875 11.336H79.7261L80.5808 14.1986C80.7875 14.8967 80.9555 15.5688 81.0848 16.2162H81.1235C81.2181 15.5857 81.3608 14.9136 81.5501 14.1986L82.3128 11.336H83.6848V11.3347Z" fill="white" />
      <path d="M90.9312 17.4627H89.5339V13.9527C89.5339 12.8711 89.1125 12.3303 88.2672 12.3303C87.8525 12.3303 87.5179 12.4785 87.2579 12.7762C87.0005 13.0739 86.8699 13.4249 86.8699 13.8266V17.4614H85.4725V13.0856C85.4725 12.5474 85.4552 11.9637 85.4219 11.3319H86.6499L86.7152 12.29H86.7539C86.9165 11.9923 87.1592 11.7466 87.4779 11.5503C87.8565 11.3215 88.2805 11.2058 88.7445 11.2058C89.3312 11.2058 89.8192 11.3904 90.2072 11.7609C90.6899 12.2146 90.9312 12.8919 90.9312 13.7915V17.4627Z" fill="white" />
      <path d="M94.7827 17.4628H93.3867V8.52271H94.7827V17.4628Z" fill="white" />
      <path d="M103.011 14.3479C103.011 15.2904 102.735 16.0626 102.183 16.6684C101.605 17.2911 100.837 17.6018 99.8808 17.6018C98.9568 17.6018 98.2221 17.3041 97.6755 16.7061C97.1288 16.1094 96.8555 15.3567 96.8555 14.4493C96.8555 13.5003 97.1368 12.7216 97.7021 12.1171C98.2675 11.5126 99.0275 11.2097 99.9835 11.2097C100.907 11.2097 101.647 11.5074 102.21 12.1041C102.743 12.6839 103.011 13.4327 103.011 14.3479ZM101.561 14.3921C101.561 13.8266 101.435 13.3417 101.186 12.9374C100.894 12.4486 100.475 12.2042 99.9341 12.2042C99.3715 12.2042 98.9448 12.4486 98.6528 12.9374C98.4021 13.3417 98.2781 13.8344 98.2781 14.4168C98.2781 14.9823 98.4035 15.4672 98.6528 15.8715C98.9555 16.3603 99.3768 16.6047 99.9208 16.6047C100.454 16.6047 100.871 16.3564 101.173 15.8585C101.433 15.4464 101.561 14.9576 101.561 14.3921Z" fill="white" />
      <path d="M109.773 17.4627H108.519L108.415 16.7568H108.376C107.947 17.3197 107.335 17.6018 106.54 17.6018C105.947 17.6018 105.467 17.4159 105.105 17.0467C104.777 16.7113 104.613 16.294 104.613 15.7987C104.613 15.0499 104.933 14.4792 105.577 14.084C106.22 13.6888 107.124 13.4951 108.288 13.5042V13.3898C108.288 12.5825 107.853 12.1795 106.983 12.1795C106.363 12.1795 105.816 12.3316 105.344 12.6332L105.06 11.7388C105.644 11.3865 106.365 11.2097 107.216 11.2097C108.859 11.2097 109.683 12.0547 109.683 13.7447V16.0015C109.683 16.6138 109.713 17.1013 109.773 17.4627ZM108.323 15.3567V14.4116C106.781 14.3856 106.011 14.7977 106.011 15.6466C106.011 15.9664 106.099 16.2056 106.279 16.3655C106.459 16.5254 106.688 16.6047 106.961 16.6047C107.268 16.6047 107.555 16.5098 107.816 16.3213C108.079 16.1315 108.24 15.891 108.3 15.5959C108.315 15.5296 108.323 15.449 108.323 15.3567Z" fill="white" />
      <path d="M117.715 17.4628H116.475L116.409 16.4787H116.371C115.975 17.2275 115.3 17.6019 114.352 17.6019C113.595 17.6019 112.964 17.312 112.464 16.7322C111.964 16.1524 111.715 15.3997 111.715 14.4754C111.715 13.4835 111.986 12.6801 112.53 12.0665C113.056 11.4945 113.702 11.2085 114.469 11.2085C115.313 11.2085 115.904 11.4854 116.24 12.0405H116.267V8.52271H117.665V15.8118C117.665 16.4085 117.682 16.9584 117.715 17.4628ZM116.267 14.8784V13.8566C116.267 13.6798 116.253 13.5368 116.228 13.4276C116.149 13.1 115.98 12.8244 115.723 12.6021C115.463 12.3798 115.149 12.268 114.788 12.268C114.267 12.268 113.859 12.4695 113.559 12.8738C113.262 13.2781 113.111 13.7942 113.111 14.4247C113.111 15.0305 113.254 15.5219 113.54 15.9002C113.843 16.3032 114.251 16.5047 114.762 16.5047C115.22 16.5047 115.587 16.337 115.866 16.0003C116.135 15.6896 116.267 15.3152 116.267 14.8784Z" fill="white" />
      <path d="M129.664 14.3479C129.664 15.2904 129.388 16.0626 128.836 16.6684C128.257 17.2911 127.492 17.6018 126.533 17.6018C125.612 17.6018 124.877 17.3041 124.328 16.7061C123.781 16.1094 123.508 15.3567 123.508 14.4493C123.508 13.5003 123.789 12.7216 124.354 12.1171C124.92 11.5126 125.68 11.2097 126.638 11.2097C127.56 11.2097 128.302 11.5074 128.862 12.1041C129.396 12.6839 129.664 13.4327 129.664 14.3479ZM128.216 14.3921C128.216 13.8266 128.09 13.3417 127.841 12.9374C127.546 12.4486 127.13 12.2042 126.586 12.2042C126.026 12.2042 125.6 12.4486 125.305 12.9374C125.054 13.3417 124.93 13.8344 124.93 14.4168C124.93 14.9823 125.056 15.4672 125.305 15.8715C125.608 16.3603 126.029 16.6047 126.573 16.6047C127.106 16.6047 127.526 16.3564 127.828 15.8585C128.085 15.4464 128.216 14.9576 128.216 14.3921Z" fill="white" />
      <path d="M137.176 17.4627H135.78V13.9527C135.78 12.8711 135.359 12.3303 134.512 12.3303C134.097 12.3303 133.763 12.4785 133.504 12.7762C133.245 13.0739 133.116 13.4249 133.116 13.8266V17.4614H131.717V13.0856C131.717 12.5474 131.701 11.9637 131.668 11.3319H132.895L132.96 12.29H132.999C133.163 11.9923 133.405 11.7466 133.723 11.5503C134.103 11.3215 134.525 11.2058 134.991 11.2058C135.576 11.2058 136.064 11.3904 136.452 11.7609C136.936 12.2146 137.176 12.8919 137.176 13.7915V17.4627Z" fill="white" />
      <path d="M146.582 12.355H145.043V15.332C145.043 16.0886 145.317 16.4669 145.858 16.4669C146.109 16.4669 146.317 16.4461 146.481 16.4032L146.517 17.4367C146.241 17.5381 145.878 17.5888 145.431 17.5888C144.879 17.5888 144.45 17.425 144.139 17.0974C143.827 16.7698 143.673 16.2186 143.673 15.4451V12.355H142.754V11.3345H143.673V10.2113L145.042 9.80835V11.3333H146.581V12.355H146.582Z" fill="white" />
      <path d="M153.978 17.4628H152.58V13.9788C152.58 12.8803 152.158 12.3304 151.314 12.3304C150.666 12.3304 150.224 12.6489 149.981 13.2859C149.94 13.4198 149.916 13.5836 149.916 13.776V17.4615H148.52V8.52271H149.916V12.216H149.942C150.382 11.5439 151.013 11.2085 151.83 11.2085C152.409 11.2085 152.888 11.3931 153.268 11.7636C153.741 12.2251 153.978 12.9115 153.978 13.8189V17.4628Z" fill="white" />
      <path d="M161.608 14.1087C161.608 14.3531 161.589 14.5585 161.556 14.7262H157.365C157.384 15.332 157.584 15.7935 157.972 16.1133C158.326 16.3993 158.784 16.5423 159.344 16.5423C159.964 16.5423 160.529 16.4461 161.038 16.2524L161.257 17.1988C160.661 17.451 159.96 17.5771 159.148 17.5771C158.174 17.5771 157.408 17.2976 156.853 16.7386C156.296 16.1796 156.02 15.4295 156.02 14.4883C156.02 13.564 156.277 12.7944 156.796 12.1808C157.337 11.5256 158.069 11.198 158.993 11.198C159.897 11.198 160.584 11.5256 161.048 12.1808C161.422 12.7008 161.608 13.3443 161.608 14.1087ZM160.274 13.7564C160.285 13.3521 160.193 13.0037 160.004 12.7099C159.761 12.3316 159.392 12.1418 158.892 12.1418C158.436 12.1418 158.064 12.3264 157.78 12.6969C157.548 12.992 157.41 13.3443 157.365 13.7564H160.274Z" fill="white" />
    </svg>

  );
};
